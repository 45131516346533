import React from "react"
import '../../common/css/global.css'
import './tab.css'

const AdvancedData = () => {
    return (
        <div className="tab-content">
            <h2 className="component-heading center">Advanced Data Analytics</h2>
                <div className="heading-dash"/>
                <div className="service-content">
                    <p className="content-text">Advanced Analytics is the autonomous or semi-autonomous examination of data or content using sophisticated techniques and tools, typically beyond those of traditional business intelligence (BI), to discover deeper insights, make predictions, or generate recommendations. Advanced analytic techniques include those such as data/text mining, machine learning, pattern matching, forecasting, visualization, semantic analysis, sentiment analysis, network and cluster analysis, multivariate statistics, graph analysis, simulation, complex event processing, neural networks.</p>
                </div>
                <div className="tab-article">
                DataArcs resources expertise on Data Architecture, Big Data Architecture, Data migration, ETL development, Hybrid Cloud development, SAP ERP, Drug Database Analytics, Market Intelligence, UX/UI Development. We provide IT development strategies and solutions for small scale to large scale companies.
                </div>
                <div className="tab-image-banner">
                    <img src="images/advanced-data-analytics.svg" alt="banner" />
                </div>
                <div className="service-content">
                    <p className="content-text">Most businesses have volumes of internal and external data at their disposal, yet their leaders continue to make decisions based on gut, past experience or one-dimensional analysis. Drawing on best practices from the fields of artificial intelligence, advanced analytics and predictive modelling, this guide helps business leaders gain a deeper understanding of how to make smarter decisions through the use of advanced data analytics.</p>
                </div>
        </div>
    )
}

export default AdvancedData;