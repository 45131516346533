import React from "react";
import {useNavigate} from 'react-router-dom'
import { Carousel } from 'react-bootstrap';
import { RoutePaths } from "../../constant";
import Button from "../button";
import "./Carousel.css"

const CustomCarousel = () => {
    const navigate = useNavigate();
    const navigateToAboutUs = () => {
        navigate(RoutePaths.aboutus);
      };
    return (
        <div className="custom-carousel">
            <Carousel>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="images/home-page-slide-img-1.png"
                    alt="First slide"
                    />
                    <Carousel.Caption>
                    <h3>Transforming <span className="bold-text">Enterprise</span> Data</h3>
                    <p>Unleash The Power Of Your Data, Generate Transformative Insights, Optimize Processes, And Enhance Your ROI With Enterprise Data Analytics.</p>
                    <Button
                    buttonType="primary"
                    buttonText="Learn More"
                    onClickHandler={navigateToAboutUs}/>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="images/home-page-slide-img-2.png"
                    alt="Second slide"
                    />

                    <Carousel.Caption>
                        <h3>Your business <span className="bold-text">through custom</span> solutions</h3>
                        <p> Generate Transformative Insights, Unleash The Power Of Your Data, Optimize Processe.</p>
                        <Button
                        buttonType="primary"
                        buttonText="Learn More"
                        onClickHandler={navigateToAboutUs}/>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="images/home-page-slide-img-3.png"
                    alt="Third slide"
                    />

                    <Carousel.Caption>
                        <h3>Improve <span className="bold-text">performance</span> and streamline</h3>
                        <p>Unleash The Power Of Your Data, Generate Transformative Insights, Optimize Processe.</p>
                        <Button
                        buttonType="primary"
                        buttonText="Learn More"
                        onClickHandler={navigateToAboutUs}/>
                    </Carousel.Caption>
                </Carousel.Item>
                </Carousel>
        </div>
    )
}

export default CustomCarousel;