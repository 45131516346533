import React from "react";
import "./button.css"
const Button = ({buttonText, onClickHandler, buttonType}) => {
    return (
        <div className="custom-button">
            <button className={buttonType} onClick={(e) => onClickHandler()}>{buttonText}</button>
        </div>
        
    )
}

export default Button;