import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Careers from "./pages/careers";
import Contact from "./pages/contactUs";
import Services from "./pages/services";
import AboutUs from "./pages/aboutUs";
import { RoutePaths } from "./constant";
import "./App.css";

const App = () => {
  const [currentPage, setCurrentpage] = useState("/");
  const [showMenu, setShowMenu] = useState(false);
  let location = useLocation();
  useEffect(() => {
    setCurrentpage(location.pathname);
  }, [location]);
  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };
  return (
    <div className={"app-container " + (showMenu ? "menu-expand" : "")}>
      <div>
        <Header
          handleMenuClick={handleMenuClick}
          currentPage={currentPage}
          showMenu={showMenu}
        />
        <div className="app-page-container">
          <Routes>
            <Route exact path={RoutePaths.home} element={<Home />} />
            <Route exact path={RoutePaths.aboutus} element={<AboutUs />} />
            <Route exact path={RoutePaths.services} element={<Services />} />
            <Route exact path={RoutePaths.careers} element={<Careers />} />
            <Route exact path={RoutePaths.contact} element={<Contact />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default App;
