import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import AdvancedData from "./advanceddata"
import BigdataContent from "./bigdatacontent"
import ClouddataContent from "./cloudcontent"
import DataMigrationContent from "./datamigrationcontent"
import DrugDataContent from "./drugdatacontent"
import UXContent from "./uxcontent"
import "./tab.css"
const Tab = ()=> {
    const [selectedContent, setSelectedContent] = useState("link-1")
    return (
        <div className="custom-tab">
            <Nav fill variant="tabs" defaultActiveKey="link-1" onSelect={(selectedKey) => setSelectedContent(selectedKey)}>
                <Nav.Item>
                    <Nav.Link eventKey="link-1">Advanced Data Analytics</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-2">Big Date/Data Science</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-3">Data Migration</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-4">Hybrid Cloud Computing</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-5">Drug Database Analytics</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-6">SAP Technical & Functional</Nav.Link>
                </Nav.Item>
            </Nav>
            {selectedContent === "link-1" && <AdvancedData />}
            {selectedContent === "link-2" && <BigdataContent />}
            {selectedContent === "link-3" && <DataMigrationContent />}
            {selectedContent === "link-4" && <ClouddataContent />}
            {selectedContent === "link-5" && <DrugDataContent />}
            {selectedContent === "link-6" && <UXContent />}
        </div>
    )
}

export default Tab;