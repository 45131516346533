import React, {useState} from "react"
import { Link } from "react-router-dom";
import { RoutePaths } from "../../constant";
import "./productcard.css"
const ProductCard = ({image, title, showHoverAnimation, hoverImage, hoverText}) => {
    const [showProductHover, setShowProductHover] = useState(false)
    return (
        <div className="product-card-component" 
        onMouseEnter={(e) => setShowProductHover(true)} 
        onMouseLeave={() => setShowProductHover(false)}
        >
            {!showProductHover && <img className="product-image" src={image} alt="product" />}
            {!showProductHover && <div className="product-title">{title}</div>}
            {showHoverAnimation && showProductHover && <div className="product-hover-section">
                <img className="product-image" src={hoverImage} alt="product" />
                <div className="product-content">
                    <p className="product-title-hover">{title}</p>
                    <p className="product-title-text">{hoverText}</p>
                    <Link to={RoutePaths.services}>
                        <button className="product-button">Learn More</button>
                    </Link>
                </div>
            </div>}
        </div>
    )
}

export default ProductCard;