import React from "react"
import {useNavigate} from 'react-router-dom'
import { RoutePaths } from "../../constant";
import Button from "../../components/button";
import "../../common/css/global.css"
import "./aboutus.css"
const AboutUs = ()=> {
    const navigate = useNavigate();
    const navigateToServices = () => {
        navigate(RoutePaths.services);
      };
    return (
        <div className="about-us-section">
            <div className="page-banner">
                <img src="images/about-us-banner.png" alt="banner"/>
                <div className="page-caption">
                <h2><span className="bold-text">About</span> Us</h2>
                <p>DataArcs is New Generation Data Company solves complex Data and create Data Visualization.</p>
                </div>
            </div>
            <div className="service-component">
                <h2 className="component-heading center">Analyze. Collaborate. Innovate.</h2>
                    <div className="heading-dash"/>
                    <div className="service-content">
                        <p className="content-text">DataArcs is specialized in data science as a service, BI solutions, and data analytics consulting. We help enterprise companies to make better decisions on time by helping them in big data analytics and visualization. We have a dedicated team of software engineers, big data developers, data scientists, business analysts, and domain experts to help you resolve critical problems of your business with vital data. We help futuristic companies to grow faster and make fewer mistakes.</p>
                    </div>
            </div>
            <div className="about-us-content-section">
                <div className="about-us-content-section-col1">
                    <p className="about-us-content-heading">About DataArcs</p>
                    <p className="about-us-content-sub-heading">DataArcs is New Generation Data Company solves complex Data and create Data Visualization.</p>
                    <p className="about-us-content-text">DataArcs understands the criticality of each and every piece of data in this modern world and about the future generation. That’s how DataArcs was born to give its expertise in the world of Big Data.</p>
                    <Button
                        buttonType="primary"
                        buttonText="Learn More"
                        onClickHandler={navigateToServices}/>
                </div>
                <div className="about-us-content-section-col2">
                    <div className="about-icon-section">
                        <div>
                            <img src="images/about-us-1.svg" alt="logo" />
                        </div>
                        <div className="about-icon-des">Provides unique solutions to every prospect using various tools and frameworks. We have a unique customer and business centric approach.</div>
                    </div>
                    <div className="about-icon-section">
                        <div>
                            <img src="images/about-us-2.svg" alt="logo" />
                        </div>
                        <div className="about-icon-des">We provide valuable insight into the fastest growing business with the best technological solution and fault tolerant architecture.</div>
                    </div>
                    <div className="about-icon-section">
                        <div>
                            <img src="images/about-us-3.svg" alt="logo" />
                        </div>
                        <div className="about-icon-des">We are one of the leading companies envisions itself as one the best Big Data Solution Providers in the near future.</div>
                    </div>
                </div>
            </div>
            <div className="service-component">
                <h2 className="component-heading center">DataArcs to create an Remarkable
Business Impact</h2>
                    <div className="heading-dash"/>
                    <div className="service-content">
                        <p className="content-text">DataArcs is specialized in data science as a service, BI solutions, and data analytics consulting. We help enterprise companies to make better decisions on time by helping them in big data analytics and visualization.</p>
                    </div>
            </div>
        </div>
    )
}

export default AboutUs;